<template>
  <section>
    <div class="header-page">
      <div class="container">
        <h1 data-aos="fade-down">Distibuidores</h1>
        <p class="subtitulo" data-aos="fade-up">
          A oferta digital mais criativa para o seu portfólio.
        </p>
      </div>
    </div>

    <div class="container page">
      <div class="text" data-aos="fade-up">
        <p>
          Inclua uma excelente oferta digital ao seu catálogo de produtos
          educacionais. Com o nosso AVA, o seu negócio se torna mais completo,
          com uma tecnologia escalável, cíclica e altamente rentável que promove
          uma solução prática e inovadora aos seus clientes.
        </p>
        <div class="valores-empresa">
          <div class="valor-item">
            <h2>Principais benefícios:</h2>
            <p>
              • Alta rentabilidade e altamente escalável;
              <br />• Fonte de receita recorrente e fidelização de clientes;
              <br />• O melhor custo-benefício do mercado; <br />• Customização
              em acordo com a sua marca ou com a nossa; <br />• Acervo com
              milhares de materiais e conteúdos inclusos.
            </p>
          </div>
          <div class="valor-item">
            <h2>Ideal para:</h2>
            <p>
              <br />• Representantes comerciais com marca própria; <br />•
              Empresas de soluções digitais; <br />• Canais de serviços para
              redes educacionais (públicas e privadas).
            </p>
          </div>
        </div>
      </div>
      <div class="imagem">
        <img
          src="@/assets/img/distribuidores.png"
          alt="distribuidores"
          class="foto-page"
          data-aos="fade-left"
        />
        <img
          src="@/assets/icons/detalhe_01.svg"
          class="detalhe"
          data-aos="fade-right"
        />
      </div>
    </div>
    <div class="container segmentos">
      <Carrosel :cards="cards" :segmentos="true">
        <div>
          <h2>Conheça <b>outros segmentos</b></h2>
          <p class="sub">
            Conheça mais sobre outros segmentos e descubra como podemos atuar na
            sua empresa.
          </p>
        </div>
      </Carrosel>
    </div>
  </section>
</template>

<script>
import Carrosel from "@/components/Carrosel";

export default {
  name: "distribuidores",
  data() {
    return {
      cards: [
        {
          path: "icons/icon-editoras.svg",
          title: "Editoras",
          date: "",
          text: "O AVA com a cara da sua editora. Conectada, interativa e 100% digital.",
          link: "/editoras",
        },
        {
          path: "icons/icon-sistema.svg",
          title: "Sistema de ensino",
          date: "",
          text: "A solução mais prática para fidelizar alunos, professores e gestores ao seu sistema de ensino.",
          link: "/sistema-de-ensino",
        },
        {
          path: "icons/icon-prefeitura.svg",
          title: "Prefeituras e Governos",
          date: "",
          text: "Sem essa de versão light. A mesma tecnologia para rede pública e privada.",
          link: "/prefeituras-e-governos",
        },
        {
          path: "icons/icon-empresas.svg",
          title: "Empresas e Promoções",
          date: "",
          text: "Marketing inteligente que fideliza e aumenta as suas vendas.",
          link: "/empresas-e-promocoes",
        },
        {
          path: "icons/icon-cooporativo.svg",
          title: "Corporativo/RH",
          date: "",
          text: "Estimule o desenvolvimento e potencial de seus colaboradores.",
          link: "/coportativo-rh",
        },
        {
          path: "icons/icon-cursos.svg",
          title: "Cursos e Ensino Técnico",
          date: "",
          text: "Qualidade, conteúdo e o engajamento que a sua instituição precisa em um só lugar!",
          link: "/cursos-e-ensino-tecnico",
        },
        {
          path: "icons/icon-universidade.svg",
          title: "Universidade",
          date: "",
          text: "Qualidade, conteúdo e o engajamento que a sua instituição precisa em um só lugar!",
          link: "/universidades",
        },
      ],
    };
  },
  components: {
    Carrosel,
  },
  created() {
    document.title = "Atheva | Distribuidores";
  },
};
</script>

