<template>
  <section>
    <slot data-aos="fade-left"></slot>
    <splide :options="options" data-aos="fade-right" class="cards">
      <splide-slide
        class="card-item"
        :class="{ segmentos: segmentos }"
        v-for="item in cards"
        :key="item.path"
      >
        <img :src="imgCard(item.path)" :alt="item.path" />

        <div class="info">
          <h3>{{ item.title }}</h3>
          <span>{{ item.date }}</span>
          <p>{{ item.text }}</p>
          <router-link :to="item.link">{{
            segmentos ? "Saiba mais >" : "Continuar lendo"
          }}</router-link>
        </div>
      </splide-slide>

      <template v-slot:controls>
        <div class="splide__progress">
          <div class="splide__progress__bar"></div>
        </div>
      </template>
    </splide>
    <div class="footer">
      <img src="@/assets/img/pattern.png" />
    </div>
  </section>
</template>

<script>
import "@splidejs/splide/dist/css/themes/splide-default.min.css";
import { Splide, SplideSlide } from "@splidejs/vue-splide";

export default {
  name: "Carrosel",
  props: {
    cards: {
      type: Array,
      default: () => [],
    },
    segmentos: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      options: {
        type: "loop",
        padding: {
          right: "5rem",
          left: "5rem",
        },
        rewind: true,
        width: 800,
        perPage: 3,
        perMove: 1,
        gap: "1rem",
      },
    };
  },
  methods: {
    imgCard(path) {
      return require(`@/assets/${path}`);
    },
  },

  components: {
    Splide,
    SplideSlide,
  },
};
</script>

<style scoped>
section {
  display: grid;
  grid-template-columns: 496px 1fr;
  height: 60vh;
  position: relative;
}
h2 {
  font-weight: normal;
  font-size: 32px;
}

.card-item {
  box-shadow: 0px 8px 16px #0000001a;
  border-radius: 8px;
  overflow: hidden;
  background: #fff;
  min-width: 284px;
}
.card-item img {
  width: 284px;
  /* height: 190px; */
}
.segmentos img {
  width: initial !important;
  margin-left: 24px;
  margin-top: 24px;
}
.info {
  padding: 16px 24px;
}
.info h3 {
  font-weight: normal;
  font-size: 20px;
  margin: 0;
  margin-bottom: 8px;
}
.segmentos h3 {
  font-weight: 800;
}
.segmentos h3::before {
  content: "";
  width: 40px;
  height: 4px;
  border-radius: 4px;
  background: #f9b010;
  display: block;
  margin: 16px 0;
}
.info span {
  font-weight: 800;
  font-size: 16px;
  display: block;
  margin-bottom: 16px;
}
.info p {
  font-size: 16px;
  line-height: 1.5;
  margin-bottom: 16px;
}
.info a {
  font-weight: 800;
  font-size: 14px;
  color: #747474;
}
.sub {
  font-size: 18px;
  margin-top: 16px;
}
.footer {
  background: transparent linear-gradient(99deg, #37475a 0%, #232f3e 100%) 0% 0%;
  position: absolute;
  bottom: 0;
  left: -300px;
  width: 150%;
  z-index: -1;
  height: 33vh;
  overflow: hidden;
}
.footer img {
  object-fit: contain;
}

@media screen and (max-width: 768px) {
  section {
    grid-template-columns: 1fr;
    height: auto;
  }
  h2 {
    font-size: 18px;
    margin-bottom: 20px;
    max-width: 300px;
  }
  .sub {
    max-width: 300px;
    font-size: 15px;
    margin-bottom: 40px;
  }
  .cards {
    width: 120%;
  }
  .splide,
  .splide__slide {
    position: initial;
  }
  .info h3 {
    font-size: 16px;
  }
  .info span,
  .info p {
    font-size: 15px;
  }
  .sub {
    font-size: 16px;
  }
  .footer {
    left: -40px;
    width: 150%;
  }
}
</style>